@use 'mixins' as *;
@import 'variables';

.about {
  @include flex(column, center, center, 2rem);
  min-height: 100vh;
  text-align: center;
  padding: 5em 2.5em 2.5em 2.5em;

  img {
    width: 100%;
    max-width: 400px;
    margin-bottom: 2rem;
  }

  h3 {
    @include fontHeader(1.8em, 700, $raleway, 1.4rem);
  }

  span {
    @include fontGradient();
  }

  p {
    white-space: break-word;
  }

  b {
    @include fontHeader(1.1em, 700, $raleway, 1rem);
  }

  .cards {
    @include flex(column, center, center, 0);

    .illustration {
      width: 100%;
      max-width: 400px;

      @keyframes imgFloat {
        0% {
          transform: translateY(0);
        }

        50% {
          transform: translateY(-.3rem);
        }

        100% {
          transform: translateY(0);
        }
      }

      animation: imgFloat 5s ease-in-out infinite;
    }

    .illustrations {
      width: 100%;
      max-width: 400px;
      position: relative;
      margin-bottom: 4rem;

      .deskSvg {
        position: absolute;
      }

      .mobileSvg {
        position: relative;
        top: 6rem;
        left: calc(50% - 218px);

        @keyframes imgFloat {
          0% {
            transform: translateY(0);
          }

          50% {
            transform: translateY(-.3rem);
          }

          100% {
            transform: translateY(0);
          }
        }

        animation: imgFloat 5s ease-in-out infinite;
      }
    }

    .card {
      @include card();
      @include fontHeader(1.1em, 0, $openSans, 1.4rem);
      background-color: #202a3ce5;
      position: relative;
      top: -5.5rem;
      transition: all 0.3s ease-in-out;
      text-align: justify;

      &:hover {
        backdrop-filter: blur(6px);
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
        -webkit-backdrop-filter: blur(6px);
      }
    }
  }

  .resume {
    @include flex(column, center, center, 1rem);
    position: relative;
    top: -2rem;

    p {
      @include fontHeader(1em, 0, $openSans, 1.4rem);
      text-align: justify;
    }
  }
}

/* media queries for desktop */
@media (min-width: 768px) {
  .about {
    @include flex(column, center, center, 2em);
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;

    .illustration,
    .illustrations {
      display: none;
    }

    .cards {
      @include flex(row, space-between, center, 2em);
      padding: 0 1em;
      position: relative;
      top: 1.2em;

      h3 {
        width: 30%;
      }

      .card {
        position: static;
        width: 70%;


        &:hover {
          backdrop-filter: none;
          transform: none;
          transition: none;
          -webkit-backdrop-filter: none;
        }
      }
    }

    .resume {
      gap: 0;
      width: 100%;
      max-width: 700px;
      top: 1em;
    }
  }
}