@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.footer {
  text-align: center;
  padding: 2.5em;
  background-color: hsl(216, 53%, 9%);
  position: relative;
  margin-top: 20rem;
}
.footer .footer__msg {
  position: absolute;
  top: -16rem;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
}
.footer .footer__msg img {
  width: 100%;
  max-width: 400px;
}
.footer .footer__contact {
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: hsl(219, 30%, 18%);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  top: -6rem;
}
.footer .footer__contact h3 {
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1.5rem;
}
.footer .footer__contact p {
  font-size: 0.82rem;
  font-weight: 0;
  font-family: "Raleway", sans-serif;
  line-height: 1.2rem;
  opacity: 0.7;
}
.footer .footer__contact a {
  background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
  border: none;
  border-radius: 2em;
  color: hsl(0, 0%, 100%);
  padding: 1em 2em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  font-weight: 700;
}
.footer .footer__contact a:hover {
  filter: brightness(1.1);
  transform: scale(1.02);
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
}
.footer .footer__icons {
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.footer .footer__icons img {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
}
.footer .footer__icons a {
  color: hsl(0, 0%, 100%);
  transition: all 0.3s ease-in-out;
}
.footer .footer__icons a:hover {
  color: hsl(176, 68%, 64%);
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}
.footer .footer__icons .icons__social {
  gap: 3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.footer .footer__icons .copy {
  font-size: 0.82rem;
  font-weight: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 0;
  opacity: 0.15;
  word-spacing: 0.2rem;
}
.footer .footer__icons .copy:hover {
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

/* media queries for desktop */
@media screen and (min-width: 768px) {
  .footer {
    gap: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% - 4.4rem);
  }
  .footer img {
    width: 100%;
    max-width: 150px;
  }
  .footer .footer__msg {
    top: -20rem;
    transform: translateX(0);
    width: 100%;
    max-width: 400px;
  }
  .footer .footer__contact {
    top: -10em;
    left: 0;
    transform: translateX(0);
    width: 100%;
    max-width: 700px;
  }
  .footer .footer__contact h3 {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 1.5rem;
  }
  .footer .footer__contact p {
    font-size: 0.9rem;
    font-weight: 0;
    font-family: "Raleway", sans-serif;
    line-height: 1.2rem;
    opacity: 0.5;
  }
  .footer .footer__icons {
    top: 0;
    left: 0;
    transform: translateX(0);
    width: 100%;
    max-width: 400px;
  }
}/*# sourceMappingURL=Footer.module.css.map */