@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.about {
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 5em 2.5em 2.5em 2.5em;
}
.about img {
  width: 100%;
  max-width: 400px;
  margin-bottom: 2rem;
}
.about h3 {
  font-size: 1.8em;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1.4rem;
}
.about span {
  background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about p {
  white-space: break-word;
}
.about b {
  font-size: 1.1em;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1rem;
}
.about .cards {
  gap: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about .cards .illustration {
  width: 100%;
  max-width: 400px;
  animation: imgFloat 5s ease-in-out infinite;
}
@keyframes imgFloat {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}
.about .cards .illustrations {
  width: 100%;
  max-width: 400px;
  position: relative;
  margin-bottom: 4rem;
}
.about .cards .illustrations .deskSvg {
  position: absolute;
}
.about .cards .illustrations .mobileSvg {
  position: relative;
  top: 6rem;
  left: calc(50% - 218px);
  animation: imgFloat 5s ease-in-out infinite;
}
@keyframes imgFloat {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-0.3rem);
  }
  100% {
    transform: translateY(0);
  }
}
.about .cards .card {
  background-color: hsl(219, 30%, 18%);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  font-size: 1.1em;
  font-weight: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 1.4rem;
  background-color: rgba(32, 42, 60, 0.8980392157);
  position: relative;
  top: -5.5rem;
  transition: all 0.3s ease-in-out;
  text-align: justify;
}
.about .cards .card:hover {
  backdrop-filter: blur(6px);
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
  -webkit-backdrop-filter: blur(6px);
}
.about .resume {
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: -2rem;
}
.about .resume p {
  font-size: 1em;
  font-weight: 0;
  font-family: "Open Sans", sans-serif;
  line-height: 1.4rem;
  text-align: justify;
}

/* media queries for desktop */
@media (min-width: 768px) {
  .about {
    gap: 2em;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
  .about .illustration,
  .about .illustrations {
    display: none;
  }
  .about .cards {
    gap: 2em;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1em;
    position: relative;
    top: 1.2em;
  }
  .about .cards h3 {
    width: 30%;
  }
  .about .cards .card {
    position: static;
    width: 70%;
  }
  .about .cards .card:hover {
    backdrop-filter: none;
    transform: none;
    transition: none;
    -webkit-backdrop-filter: none;
  }
  .about .resume {
    gap: 0;
    width: 100%;
    max-width: 700px;
    top: 1em;
  }
}/*# sourceMappingURL=About.module.css.map */