.main {
  padding: 5em 2.5em 2.5em 2.5em;
  text-align: justify;
}

@media (min-width: 768px) {
  .main {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 8em 0 0 0;
  }
}