@use "mixins" as *;
@import "variables";

.header {
  width: 100%;

  .header__navbar {
    @include flex(row-reverse, space-between, center, 0);

    .logo {
      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      animation: spin 100s linear infinite;
    }

    //menu side mobile
    .icon__figure {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      position: fixed;
      top: 0;
      z-index: 2;
      margin: 1em;

      &:active {
        transition: all ease-in-out;
        transform: scale(0.8);
        opacity: 0.5;
      }
    }

    .navbar__menuMobile {
      position: absolute;
      top: 0;
      right: 0;
      transition: all .3s ease-in-out;
      width: 100%;
      transform: translateY(-100%);
      z-index: 1;

      .list {
        @include flex(column, center, center, 2em);
        /* background blur */
        backdrop-filter: blur(5px);
        background-color: hsla(217, 27%, 15%, 0.8);
        min-height: 100vh;
        font-size: 2em;
        font-family: $raleway;
        font-weight: 700;

        li>a {
          color: $White;
          text-decoration: none;

          &:active {
            color: $Cyan;
          }
        }
      }
    }

    .show {
      transition: all .6s ease-in-out;
      transform: translateX(0);
      position: fixed;
    }

    .header__navbarDesktop {
      display: none;
    }
  }

}

/* media queries for desktop */
@media (min-width: 768px) {
  .header {
    @include flex(row, center, center, 0);

    .header__navbar {
      justify-content: center;
      width: 100%;

      .navbar__menuMobile {
        display: none;
      }

      .header__navbarDesktop {
        @include flex(row, flex-end, center, 0);
        position: fixed;
        top: 0;
        width: calc(100% - 6em);
        padding: 2em 5em;
        z-index: 99;
        border-radius: 0 0 12px 12px;
        background-color: hsla(217, 27%, 13%, 0.9);
        backdrop-filter: blur(5px);

        .logo {
          animation: none;
        }

        ul {
          @include flex(row, center, center, 2rem);

          .item {
            @include fontHeader(1rem, 500, $raleway, 0);

            a {
              color: $White;
              text-decoration: none;
              opacity: 0.5;

              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}