@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.main {
  gap: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 5em 2.5em 2.5em 2.5em;
}
.main .main__hero {
  position: relative;
  gap: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.main .main__hero .hero__img {
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
  width: 100%;
  max-width: 400px;
  animation: float 5s ease-in-out infinite;
}
@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
.main .main__text {
  gap: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.main .main__text .text__title {
  font-size: 2em;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1.5;
}
.main .main__text .text__subtitle {
  font-size: 1.2em;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  line-height: 1.3;
}
.main .main__text .text__btn {
  font-size: 1.2em;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1.3;
  background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
  border: none;
  border-radius: 2em;
  color: hsl(0, 0%, 100%);
  padding: 1em 2em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}
@keyframes bright {
  0% {
    filter: brightness(1);
  }
  50% {
    filter: brightness(1.1);
  }
  100% {
    filter: brightness(1);
  }
}
.main .main__text .text__btn:hover {
  animation: bright 1.2s infinite;
  transform: scale(1.05);
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
}

/* media queries for desktop */
@media only screen and (min-width: 768px) {
  .main {
    padding: 5em 5em 2.5em 5em;
    width: 80%;
    max-width: 550px;
  }
  .main .main__hero .hero__img {
    max-width: 600px;
  }
  .main .main__text {
    gap: 2em;
  }
  .main .main__text .text__title {
    font-size: 2em;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 1.5;
  }
  .main .main__text .text__subtitle {
    font-size: 1.2em;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    line-height: 1.3;
  }
  .main .main__text .text__btn {
    font-size: 1.5em;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 1.3;
  }
}/*# sourceMappingURL=Head.module.css.map */