html {

  /* Estilos para la barra de desplazamiento */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Estilos para el riel de la barra de desplazamiento */
  ::-webkit-scrollbar-track {
    background-color: #1c2431;
  }

  /* Estilos para el pulgar de la barra de desplazamiento */
  ::-webkit-scrollbar-thumb {
    background-color: #202a3ccd;
    border-radius: 5px;
  }

  /* Estilos para el pulgar al pasar el ratón sobre él */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #202a3ccd;
  }

  /* Estilos para las flechas de desplazamiento */
  ::-webkit-scrollbar-button {
    display: none;
  }

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}