@use './mixins' as *;
@import './variables';

.section {
  text-align: center;
  @include flex(column, center, center, 4rem);
  padding: 2.5em;
  position: relative;

  .section__container {
    @include flex(column, center, center, 2rem);

    .container__info {
      .info__title {
        @include fontHeader(1.5rem, 700, $raleway, 1.5rem);
        margin-bottom: 1rem;
      }

      .info__text {
        @include fontHeader(1em, 400, $openSans, 1.5rem);
        padding: 0 1.5em;
      }
    }
  }
}

/* media queries for desktop */
@media screen and (min-width: 768px) {
  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 5em 2.5em 2.5em 2.5em;
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;

    .section__container {
      @include flex(column, center, center, 2rem);

      .container__info {
        .info__title {
          @include fontHeader(2rem, 700, $raleway, 1.5rem);
          margin-bottom: 1rem;
        }

        .info__text {
          @include fontHeader(1.2em, 400, $openSans, 1.5rem);
          padding: 0 1.5em;
        }
      }
    }
  }
}