@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.section {
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 5em 2.5em 2.5em 2.5em;
  text-align: justify;
}
.section img {
  width: 100%;
  max-width: 400px;
  animation: breath 3s infinite;
}
@keyframes breath {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}
.section h3 {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}
.section span {
  background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1rem;
}
.section p {
  font-size: 1em;
  font-weight: 100;
  font-family: "Open Sans", sans-serif;
  line-height: 1.2rem;
}
.section ul {
  list-style: disc;
  margin-top: 0.7em;
  margin-left: 1.5em;
}
.section ul li {
  margin-bottom: 0.3em;
}
.section hr {
  width: 100%;
  max-width: 400px;
  opacity: 0.1;
  border: none;
  border-bottom: 1px solid hsl(0, 0%, 100%);
}
.section .section__title h5 {
  font-size: 1.3rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}
.section .btn__footer {
  position: relative;
  width: 100%;
  max-width: 400px;
  opacity: 0.2;
}
.section .btn__footer:hover {
  opacity: 1;
}
.section .btn__footer .arrow {
  background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
  border: none;
  border-radius: 50%;
  color: hsl(0, 0%, 100%);
  padding: 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  position: fixed;
  bottom: 2em;
  right: 2em;
  width: 20px;
  height: 20px;
}

/* media queries for desktop */
@media (min-width: 1024px) {
  .section {
    padding: 5em 0;
    text-align: justify;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  .section img {
    max-width: 600px;
  }
  .section h3 {
    font-size: 2.2rem;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    line-height: 2.5rem;
  }
  .section span {
    background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 1.5rem;
  }
  .section p {
    font-size: 1.2rem;
    font-weight: 100;
    font-family: "Raleway", sans-serif;
    line-height: 1.2rem;
  }
  .section ul li {
    font-size: 1.2em;
    font-weight: 100;
    font-family: "Raleway", sans-serif;
    line-height: 1.2rem;
  }
  .section .section__title h5 {
    font-size: 1.5rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 1.5rem;
  }
  .section .btn__footer {
    display: none;
  }
}/*# sourceMappingURL=AboutMore.module.css.map */