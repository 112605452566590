@use 'mixins' as *;
@import 'variables';

.main {
  @include flex(column, center, center, 2em);
  text-align: center;
  padding: 5em 2.5em 2.5em 2.5em;

  .main__hero {
    position: relative;
    @include flex(column, center, center, 0);

    .hero__img {
      filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
      width: 100%;
      max-width: 400px;

      @keyframes float {
        0% {
          transform: translateY(0px);
        }

        50% {
          transform: translateY(15px);
        }

        100% {
          transform: translateY(0px);
        }
      }

      animation: float 5s ease-in-out infinite;
    }
  }

  .main__text {
    @include flex(column, center, center, 1.5rem);
    position: relative;

    .text__title {
      @include fontHeader(2em, 700, $raleway, 1.5);
    }

    .text__subtitle {
      @include fontHeader(1.2em, 400, $openSans, 1.3);
    }

    .text__btn {
      @include fontHeader(1.2em, 700, $raleway, 1.3);
      @include button($Cyan, $Blue, none, 2em, $White, 1em 2em, pointer, none);

      @keyframes bright {
        0% {
          filter: brightness(1);
        }

        50% {
          filter: brightness(1.1);
        }

        100% {
          filter: brightness(1);
        }
      }

      &:hover {
        animation: bright 1.2s infinite;
        transform: scale(1.05);
        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

/* media queries for desktop */
@media only screen and (min-width: 768px) {
  .main {
    padding: 5em 5em 2.5em 5em;
    width: 80%;
    max-width: 550px;

    .main__hero {
      .hero__img {
        max-width: 600px;
      }
    }

    .main__text {
      gap: 2em;
      .text__title {
        @include fontHeader(2em, 700, $raleway, 1.5);
      }

      .text__subtitle {
        @include fontHeader(1.2em, 400, $openSans, 1.3);
      }

      .text__btn {
        @include fontHeader(1.5em, 700, $raleway, 1.3);
      }
    }
  }
}