@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.services {
  gap: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5em 2.5em 2.5em 2.5em;
  text-align: start;
}
.services span {
  background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.services h3 {
  font-size: 2rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
}
.services h4 {
  font-size: 1.2rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 2rem;
}
.services a {
  text-decoration: none;
}
.services .a:hover {
  text-decoration: underline;
  text-decoration-color: hsl(176, 68%, 64%);
}
.services .services__list {
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.services .services__readText ul {
  padding: 1em 2em;
  list-style: decimal;
}
.services .services__readText ul li {
  margin-bottom: 1em;
}

/* media queries for desktop */
@media (min-width: 768px) {
  .services {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 8em 0 2em 0;
    min-height: 100vh;
  }
  .services h3 {
    font-size: 2.3rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 2.5rem;
  }
  .services h4 {
    font-size: 1.3rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 2rem;
  }
  .services p {
    font-size: 1rem;
    font-weight: 100;
    font-family: "Open Sans", sans-serif;
    line-height: 1.2rem;
  }
  .services .services__list {
    gap: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
}/*# sourceMappingURL=Services.module.css.map */