@use 'mixins' as *;
@import 'variables';

.footer {
  text-align: center;
  padding: 2.5em;
  background-color: $Dark-Blue-footer;
  position: relative;
  margin-top: 20rem;

  .footer__msg {
    position: absolute;
    top: -16rem;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;

    img {
      width: 100%;
      max-width: 400px;
    }
  }

  .footer__contact {
    @include flex(column, center, center, 2rem);
    @include card();
    position: relative;
    top: -6rem;

    h3 {
      @include fontHeader(1.3rem, 700, $raleway, 1.5rem);
    }

    p {
      @include fontHeader(.82rem, 0, $raleway, 1.2rem);
      opacity: .7;
    }

    a {
      @include button($Cyan, $Blue, none, 2em, $White, 1em 2em, pointer, none);
      transition: all 0.3s ease-in-out;
      font-weight: 700;

      &:hover {
        filter: brightness(1.1);
        transform: scale(1.02);
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .footer__icons {
    @include flex(column, center, center, 2rem);
    position: relative;

    img {
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.4));
    }

    a {
      color: $White;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: $Cyan;
        transform: scale(1.1);
        transition: all 0.3s ease-in-out;
      }
    }

    .icons__social {
      @include flex(row, center, center, 3rem);
    }

    .copy {
      @include fontHeader(.82rem, 0, $openSans, 0);
      opacity: .15;
      word-spacing: .2rem;

      &:hover {
        opacity: .5;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

/* media queries for desktop */
@media screen and (min-width: 768px) {
  .footer {
    @include flex(column, center, center, 0);
    width: calc(100% - 4.4rem);

    img {
      width: 100%;
      max-width: 150px;
    }

    .footer__msg {
      top: -20rem;
      transform: translateX(0);
      width: 100%;
      max-width: 400px;
    }

    .footer__contact {
      top: -10em;
      left: 0;
      transform: translateX(0);
      width: 100%;
      max-width: 700px;

      h3 {
        @include fontHeader(1.5rem, 700, $raleway, 1.5rem);
      }

      p {
        @include fontHeader(.9rem, 0, $raleway, 1.2rem);
        opacity: .5;
      }
    }

    .footer__icons {
      top: 0;
      left: 0;
      transform: translateX(0);
      width: 100%;
      max-width: 400px;
    }
  }
}