@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */
html {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.main {
  gap: 8em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}/*# sourceMappingURL=Main.module.css.map */