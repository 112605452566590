@use 'mixins.scss' as *;
@import 'variables.scss';

.main {
  @include flex(column, center, center, 3rem);
  min-height: 100vh;
  padding: 0 2.5rem;
  text-align: center;

  .icon {
    @keyframes rotateIcon {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.05);
      }

      100% {
        transform: scale(1);
      }
    }

    animation: rotateIcon 3s alternate infinite;
  }

  h1 {
    @include fontHeader(4rem, 700, $openSans, 1);
  }

  h2 {
    @include fontHeader(1.6rem, 500, $raleway, 1);
    opacity: 0.5;
  }

  p {
    @include fontHeader(.9em, 400, $openSans, 1rem);
  }

  .back {
    display: none;
  }
}

/* media query desktop */
@media (min-width: 768px) {
  figure {
    display: none;
  }

  .navbar__menuDesktop {
    display: inline-block;

  }

  .navbar__menuMobile {
    display: none;
  }

  .main {
    width: 500px;
    margin: 0 auto;

    h1 {
      @include fontHeader(5rem, 700, $openSans, 1);
    }

    h2 {
      @include fontHeader(2rem, 500, $raleway, 1);
    }

    p {
      @include fontHeader(1.2rem, 400, $openSans, 1);
      text-align: justify;
    }

    .back {
      @include fontHeader(1em, 700, $raleway, 1);
      @include button($Cyan, $Blue, none, 50px, $White, 1rem 2rem, pointer, none);
      display: inline-block;
      margin-top: 2rem;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);

      &:hover {
        transform: translateY(-5px);
        filter: brightness(110%);
      }
    }
  }
}