@use 'mixins.scss' as *;
@import 'variables.scss';

.middleSection {
  @include flex(column, center, center, 2.5rem);
  text-align: center;
  padding: 2.5em;
  position: relative;

  .middle {
    @include flex(column, center, center, 2rem);

    .middle__info {
      p {
        @include fontHeader(1.1em, 400, $raleway, 1.5rem);
        color: $White;
      }
    }

    hr {
      width: 50%;
      border: none;
      height: .07rem;
      background-color: $White;
      opacity: .2;
    }

    .middle__faq {
      p {
        @include fontHeader(.8em, 400, $raleway, 1.2rem);
        opacity: .7;
        color: $Cyan;
      }
    }
  }
}

/* media queries for desktop */
@media screen and (min-width: 768px) {
  .middleSection {
    @include flex(row, center, center, 2.5rem);
    padding: 5em 2.5em 2.5em 2.5em;
    width: 90%;
    max-width: 1000px;

    img {
      filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
    }

    .middle {
      gap: 1rem;

      .middle__info {
        p {
          @include fontHeader(1.3em, 400, $raleway, 1.5rem);
          color: $White;
        }
      }

      hr {
        display: none
      }

      .middle__faq {
        p {
          @include fontHeader(1em, 400, $raleway, 1.2rem);
          opacity: .5;
          color: $Cyan;
        }
      }
    }
  }
}