@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
html {
  scroll-behavior: smooth;
}

body {
  color: hsl(0, 0%, 100%);
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  background-color: hsl(218, 28%, 13%);
  width: 100%;
}/*# sourceMappingURL=App.module.css.map */