@use 'mixins' as *;
@import 'variables';

.section {
  @include flex(column, center, center, 2rem);
  min-height: 100vh;
  padding: 5em 2.5em 2.5em 2.5em;
  text-align: justify;

  img {
    width: 100%;
    max-width: 400px;

    @keyframes breath {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.02);
      }

      100% {
        transform: scale(1);
      }
    }

    animation: breath 3s infinite;
  }

  h3 {
    @include fontHeader(1.5rem, 700, $raleway, 2.5rem);
    margin-bottom: 1rem;
  }

  span {
    @include fontGradient();
    @include fontHeader(1rem, 700, $raleway, 1rem);
  }

  p {
    @include fontHeader(1em, 100, $openSans, 1.2rem);

  }

  ul {
    list-style: disc;
    margin-top: .7em;
    margin-left: 1.5em;

    li {
      margin-bottom: .3em;
    }
  }

  hr {
    width: 100%;
    max-width: 400px;
    opacity: .1;
    border: none;
    border-bottom: 1px solid $White;
  }

  .section__title {
    h5 {
      @include fontHeader(1.3rem, 700, $raleway, 1.5rem);
      margin-bottom: 1rem;
    }
  }

  .btn__footer {
    position: relative;
    width: 100%;
    max-width: 400px;
    opacity: .2;

    &:hover {
      opacity: 1;
    }
    .arrow {
      @include button($Cyan, $Blue, none, 50%, $White, 1em, pointer, none);
      position: fixed;
      bottom: 2em;
      right: 2em;
      width: 20px;
      height: 20px;
    }
  }
}

/* media queries for desktop */
@media (min-width: 1024px) {
  .section {
    padding: 5em 0;
    text-align: justify;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    img {
      max-width: 600px;
    }

    h3 {
      @include fontHeader(2.2rem, 700, $openSans, 2.5rem);
    }

    span {
      @include fontGradient();
      @include fontHeader(1.2rem, 700, $raleway, 1.5rem);
    }

    p {
      @include fontHeader(1.2rem, 100, $raleway, 1.2rem);
    }
    
    ul {
      li {
        @include fontHeader(1.2em, 100, $raleway, 1.2rem);
      }
    }

    .section__title {
      h5 {
        @include fontHeader(1.5rem, 700, $raleway, 1.5rem);
      }
    }

    .btn__footer {
      display: none;
    }
  }
}