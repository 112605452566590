@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.middleSection {
  gap: 2.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 2.5em;
  position: relative;
}
.middleSection .middle {
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.middleSection .middle .middle__info p {
  font-size: 1.1em;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  line-height: 1.5rem;
  color: hsl(0, 0%, 100%);
}
.middleSection .middle hr {
  width: 50%;
  border: none;
  height: 0.07rem;
  background-color: hsl(0, 0%, 100%);
  opacity: 0.2;
}
.middleSection .middle .middle__faq p {
  font-size: 0.8em;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  line-height: 1.2rem;
  opacity: 0.7;
  color: hsl(176, 68%, 64%);
}

/* media queries for desktop */
@media screen and (min-width: 768px) {
  .middleSection {
    gap: 2.5rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    padding: 5em 2.5em 2.5em 2.5em;
    width: 90%;
    max-width: 1000px;
  }
  .middleSection img {
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
  }
  .middleSection .middle {
    gap: 1rem;
  }
  .middleSection .middle .middle__info p {
    font-size: 1.3em;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    line-height: 1.5rem;
    color: hsl(0, 0%, 100%);
  }
  .middleSection .middle hr {
    display: none;
  }
  .middleSection .middle .middle__faq p {
    font-size: 1em;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    line-height: 1.2rem;
    opacity: 0.5;
    color: hsl(176, 68%, 64%);
  }
}/*# sourceMappingURL=MiddleContent.module.css.map */