@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.main {
  gap: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 0 2.5rem;
  text-align: center;
}
.main .icon {
  animation: rotateIcon 3s alternate infinite;
}
@keyframes rotateIcon {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.main h1 {
  font-size: 4rem;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
  line-height: 1;
}
.main h2 {
  font-size: 1.6rem;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
  line-height: 1;
  opacity: 0.5;
}
.main p {
  font-size: 0.9em;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  line-height: 1rem;
}
.main .back {
  display: none;
}

/* media query desktop */
@media (min-width: 768px) {
  figure {
    display: none;
  }
  .navbar__menuDesktop {
    display: inline-block;
  }
  .navbar__menuMobile {
    display: none;
  }
  .main {
    width: 500px;
    margin: 0 auto;
  }
  .main h1 {
    font-size: 5rem;
    font-weight: 700;
    font-family: "Open Sans", sans-serif;
    line-height: 1;
  }
  .main h2 {
    font-size: 2rem;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    line-height: 1;
  }
  .main p {
    font-size: 1.2rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    line-height: 1;
    text-align: justify;
  }
  .main .back {
    font-size: 1em;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 1;
    background: linear-gradient(to right, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
    border: none;
    border-radius: 50px;
    color: hsl(0, 0%, 100%);
    padding: 1rem 2rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    display: inline-block;
    margin-top: 2rem;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.2);
  }
  .main .back:hover {
    transform: translateY(-5px);
    filter: brightness(110%);
  }
}/*# sourceMappingURL=Error.module.css.map */