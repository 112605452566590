@use 'variables' as *;

html {
  //smoth scroll
  scroll-behavior: smooth;
}

body {
  color: $White;
  font-size: 14px;
  font-family: $openSans;
  background-color: $Dark-Blue-mainBg;
  width: 100%;
}