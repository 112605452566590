@use 'mixins' as *;
@import 'variables';

.services {
  @include flex(column, center, center, 0);
  padding: 5em 2.5em 2.5em 2.5em;
  text-align: start;

  span {
    @include fontGradient();
  }

  h3 {
    @include fontHeader(2rem, 700, $raleway, 2.5rem);
    margin-bottom: 1.5rem;
  }

  h4 {
    @include fontHeader(1.2rem, 700, $raleway, 2rem);
  }

  a {
    text-decoration: none;
  }

  .a:hover {
    text-decoration: underline;
    text-decoration-color: $Cyan;
  }

  .services__list {
    @include flex(column, flex-start, center, 2rem);
  }

  .services__readText {
    ul {
      padding: 1em 2em;
      list-style: decimal;

      li {
        margin-bottom: 1em;
      }
    }
  }
}

/* media queries for desktop */
@media (min-width: 768px) {
  .services {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 8em 0 2em 0;
    min-height: 100vh;

    h3 {
      @include fontHeader(2.3rem, 700, $raleway, 2.5rem);
    }

    h4 {
      @include fontHeader(1.3rem, 700, $raleway, 2rem);
    }

    p {
      @include fontHeader(1rem, 100, $openSans, 1.2rem);
    }

    .services__list {
      @include flex(column, flex-start, center, 4rem);
    }
  }
}