@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.section {
  text-align: center;
  gap: 4rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 2.5em;
  position: relative;
}
.section .section__container {
  gap: 2rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.section .section__container .container__info .info__title {
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Raleway", sans-serif;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}
.section .section__container .container__info .info__text {
  font-size: 1em;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  line-height: 1.5rem;
  padding: 0 1.5em;
}

/* media queries for desktop */
@media screen and (min-width: 768px) {
  .section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 5em 2.5em 2.5em 2.5em;
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
  }
  .section .section__container {
    gap: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .section .section__container .container__info .info__title {
    font-size: 2rem;
    font-weight: 700;
    font-family: "Raleway", sans-serif;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .section .section__container .container__info .info__text {
    font-size: 1.2em;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    line-height: 1.5rem;
    padding: 0 1.5em;
  }
}/*# sourceMappingURL=Content.module.css.map */