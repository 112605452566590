@import 'mixins.scss';

html {
  // unselectable text
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.main {
  @include flex(column, center, center, 8em);
}