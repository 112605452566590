@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap');

/* fonts */
$openSans: 'Open Sans', sans-serif; // body
$raleway: 'Raleway', sans-serif; // headings, header navbar n call to action

/* primary colors default theme */
$Dark-Blue: hsl(217, 28%, 15%); // intro n email sign up background // #1c2431
$Dark-Blue-mainBg: hsl(218, 28%, 13%); // main bg // #181f2a
$Dark-Blue-footer: hsl(216, 53%, 9%); // footer bg // #0b1523
$Dark-Blue-testimonial: hsl(219, 30%, 18%); //testimonial bg // #202a3c

/* accent colors default theme */
$Cyan: hsl(176, 68%, 64%); //inside call-to-action gradient #65e2d9
$Blue: hsl(198, 60%, 50%); //inside call-to-action gradient
$LightRed: hsl(0, 100%, 63%); //error 

/* neutral colors default theme */
$White: hsl(0, 0%, 100%); //text...