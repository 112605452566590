@import 'variables';

/* flex */
@mixin flex($direction, $justify, $align, $gap) {
  gap: $gap;
  display: flex;
  align-items: $align;
  flex-direction: $direction;
  justify-content: $justify;
}

@mixin fontHeader($size, $weight, $family, $lineHeight) {
  font-size: $size;
  font-weight: $weight;
  font-family: $family;
  line-height: $lineHeight;
}

@mixin button ($color1, $color2, $border, $borderRadius, $fontColor, $padding, $cursor, $textDecoration) {
  background: linear-gradient(to right, $color1, $color2);
  border: $border;
  border-radius: $borderRadius;
  color: $fontColor;
  padding: $padding;
  cursor: $cursor;
  transition: all 0.3s ease-in-out;
  text-decoration: $textDecoration;
}

@mixin card() {
  background-color: $Dark-Blue-testimonial;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
}

@mixin fontGradient() {
  background: linear-gradient(to right, $Cyan, $Blue);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}