@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Raleway:wght@400;700&display=swap");
/* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
/* flex */ /* fonts */
/* primary colors default theme */
/* accent colors default theme */
/* neutral colors default theme */
.header {
  width: 100%;
}
.header .header__navbar {
  gap: 0;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.header .header__navbar .logo {
  animation: spin 100s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.header .header__navbar .icon__figure {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 2;
  margin: 1em;
}
.header .header__navbar .icon__figure:active {
  transition: all ease-in-out;
  transform: scale(0.8);
  opacity: 0.5;
}
.header .header__navbar .navbar__menuMobile {
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s ease-in-out;
  width: 100%;
  transform: translateY(-100%);
  z-index: 1;
}
.header .header__navbar .navbar__menuMobile .list {
  gap: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* background blur */
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background-color: hsla(217, 27%, 15%, 0.8);
  min-height: 100vh;
  font-size: 2em;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}
.header .header__navbar .navbar__menuMobile .list li > a {
  color: hsl(0, 0%, 100%);
  text-decoration: none;
}
.header .header__navbar .navbar__menuMobile .list li > a:active {
  color: hsl(176, 68%, 64%);
}
.header .header__navbar .show {
  transition: all 0.6s ease-in-out;
  transform: translateX(0);
  position: fixed;
}
.header .header__navbar .header__navbarDesktop {
  display: none;
}

/* media queries for desktop */
@media (min-width: 768px) {
  .header {
    gap: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .header .header__navbar {
    justify-content: center;
    width: 100%;
  }
  .header .header__navbar .navbar__menuMobile {
    display: none;
  }
  .header .header__navbar .header__navbarDesktop {
    gap: 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    width: calc(100% - 6em);
    padding: 2em 5em;
    z-index: 99;
    border-radius: 0 0 12px 12px;
    background-color: hsla(217, 27%, 13%, 0.9);
    -webkit-backdrop-filter: blur(5px);
            backdrop-filter: blur(5px);
  }
  .header .header__navbar .header__navbarDesktop .logo {
    animation: none;
  }
  .header .header__navbar .header__navbarDesktop ul {
    gap: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }
  .header .header__navbar .header__navbarDesktop ul .item {
    font-size: 1rem;
    font-weight: 500;
    font-family: "Raleway", sans-serif;
    line-height: 0;
  }
  .header .header__navbar .header__navbarDesktop ul .item a {
    color: hsl(0, 0%, 100%);
    text-decoration: none;
    opacity: 0.5;
  }
  .header .header__navbar .header__navbarDesktop ul .item a:hover {
    opacity: 1;
  }
}/*# sourceMappingURL=Nav.module.css.map */